// import '../App.css';
import React from 'react'
import { ClientResponseOptionType } from './sdk_interface.js'
const Login = ({ setJourneyData }) => {
  let otp_type = ''

  const mmLogin = async () => {
    let elements = ''
    elements = document.getElementsByName('otp_type')
    // elements = document.getElementsByName('otp_type');
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].checked) {
        otp_type = elements[i].value
      }
    }
    let phone_number = ''
    phone_number = document.getElementById('phone').value
    // let otp_type = ""
    let data = {
      option: ClientResponseOptionType.ClientInput,
      data: {
        idverify_selection: 'PHONE_VERIFICATION',
        firstName: document.getElementById('firstName').value,
        lastName: document.getElementById('lastName').value,
        phone: phone_number,
        phone_number: phone_number,
        address: document.getElementById('address').value,
        otp_type: otp_type === '' ? 'sms' : otp_type,
        nmuid: document.getElementById('nmuid').value
          ? document.getElementById('nmuid').value
          : 'ABC123',
        ticketid: document.getElementById('ticketid').value
          ? document.getElementById('ticketid').value
          : 'ABC123'
      }
    }
    setJourneyData(data)
  }

  const kbaLogin = () => {
    // pageUtils.hide('login_form')
    let data = {
      option: ClientResponseOptionType.ClientInput,
      data: {
        idverify_selection: 'KBA',
        firstName: document.getElementById('firstName').value,
        lastName: document.getElementById('lastName').value,
        phone: document.getElementById('phone').value,
        address: document.getElementById('address').value,
        nmuid: document.getElementById('nmuid').value
          ? document.getElementById('nmuid').value
          : 'ABC123',
        ticketid: document.getElementById('ticketid').value
          ? document.getElementById('ticketid').value
          : 'ABC123'
      }
    }

    setJourneyData(data)
  }

  const dvLogin = () => {
    let data = {
      option: ClientResponseOptionType.ClientInput,
      data: {
        idverify_selection: 'DOC_VERIFICATION',
        firstName: document.getElementById('firstName').value,
        lastName: document.getElementById('lastName').value,
        phone: document.getElementById('phone').value,
        address: document.getElementById('address').value,
        nmuid: document.getElementById('nmuid').value
          ? document.getElementById('nmuid').value
          : 'ABC123',
        ticketid: document.getElementById('ticketid').value
          ? document.getElementById('ticketid').value
          : 'ABC123'
      }
    }
    setJourneyData(data)
  }

  const partiesLogin = () => {
    let data = {
      option: ClientResponseOptionType.ClientInput,
      data: {
        idverify_selection: 'PARTIES',
        nmuid: document.getElementById('nmuid').value,
        ticketid: document.getElementById('ticketid').value
      }
    }
    setJourneyData(data)
  }

  return (
    <>
      <div id='login_form' className='column gap hidden'>
        <div className='column gap'>
          <h3 id='login_form_title' style={{ fontWeight: 'bold' }}>
            {"Let's see who you are."}
          </h3>
          <div
            id='login_form_text'
            style={{ fontFamily: 'Sans-serif' }}
            className='column long-text'
          >
            <div>
              <div className='form-questions '>
                <label style={{ display: 'inline' }}> First Name: </label>
                <input
                  style={{ display: 'inline', width: '50%' }}
                  type='text'
                  id='firstName'
                  className='focus-visible form-input'
                  width='50%'
                  autoComplete='on'
                  placeholder=''
                />
              </div>
              <div className='form-questions '>
                <label style={{ display: 'inline' }}> Last Name: </label>
                <input
                  style={{ display: 'inline', width: '50%' }}
                  type='text'
                  id='lastName'
                  className='focus-visible form-input'
                  width='50%'
                  autoComplete='on'
                  placeholder=''
                />
              </div>
              <div className='form-questions '>
                <label style={{ display: 'inline' }}> Phone: </label>
                <input
                  style={{ display: 'inline', width: '50%' }}
                  id='phone'
                  className='focus-visible form-input'
                  type='tel'
                  maxLength='12'
                  placeholder=''
                  pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
                />
              </div>
              <div className='form-questions '>
                <label style={{ display: 'inline' }}> Address: </label>
                <input
                  style={{ display: 'inline', width: '50%' }}
                  type='text'
                  id='address'
                  className='focus-visible form-input'
                  width='50%'
                  autoComplete='on'
                  placeholder=''
                />
              </div>

              <div className='form-questions '>
                <label style={{ display: 'inline' }}> NM uid: </label>
                <input
                  style={{ display: 'inline', width: '50%' }}
                  type='text'
                  id='nmuid'
                  className='focus-visible form-input'
                  width='50%'
                  autoComplete='on'
                  placeholder=''
                />
              </div>

              <div className='form-questions '>
                <label style={{ display: 'inline' }}> Ticket Id: </label>
                <input
                  style={{ display: 'inline', width: '50%' }}
                  type='text'
                  id='ticketid'
                  className='focus-visible form-input'
                  width='50%'
                  autoComplete='on'
                  placeholder=''
                />
              </div>

              <div className='form-questions '>
                <label style={{ display: 'inline' }}>OTP Type: </label>

                <div className='form-input'>
                  <div className='smsORvoice'>SMS:</div>
                  <input
                    type='radio'
                    className='focus-visible radio '
                    width='100%'
                    autoComplete='on'
                    placeholder=''
                    name='otp_type'
                    value='sms'
                  />

                  <div className='smsORvoice'>Voice:</div>
                  <input
                    type='radio'
                    className='focus-visible radio'
                    width='100%'
                    autoComplete='on'
                    placeholder=''
                    name='otp_type'
                    value='voice'
                  />
                </div>
              </div>
            </div>
            <div>
              <button
                style={{ display: 'inline' }}
                id='mm_button'
                className='quarter-width login rounded'
                onClick={mmLogin}
              >
                Mobile Match
              </button>
              <button
                style={{ display: 'inline' }}
                id='kba_button'
                className='quarter-width login rounded'
                onClick={kbaLogin}
              >
                KBA
              </button>
              <button
                style={{ display: 'inline' }}
                id='dv_button'
                className='quarter-width login rounded'
                onClick={dvLogin}
              >
                Document Varification
              </button>
              <button
                style={{ display: 'inline' }}
                id='parties_button'
                className='quarter-width login rounded'
                onClick={partiesLogin}
              >
                Parties
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Login
