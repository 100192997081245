export const PROGRESS_TRACKER_STEPS = [
  { label: 'Verify Identity', active: true },
  { label: 'Enroll into eDelivery' },
  { label: 'Create Login' },
  { label: 'Setup Authenticator' }
]

export const PAGES = {
  form_disclosure: {
    ID: 'form_disclosure',
    title: 'Verify your identity',
    subTitle: 'Review the identity verification disclosure to continue',
    icon: 'user',
    submit: 'I Accept',
    cancel: 'Cancel',
    scrollable: true
  },
  none: {
    ID: 'none'
  },

  form_update_phone_no: {
    ID: 'form_update_phone_no',
    title: 'Verify your identity',
    subTitle: 'Follow the prompts to verify your phone number.',
    icon: 'user',
    submit: 'Submit'
  },
  form_phone_verification: {
    ID: 'form_phone_verification',
    title: 'Verify your identity',
    subTitle: '',
    icon: 'user',
    submit: 'Verify'
  },
  form_idverify_options: {
    ID: 'form_idverify_options',
    title: 'Verify your identity',
    subTitle: '',
    icon: 'user',
    submit: 'Next',

    message:
      'This could either mean your phone did not meet verification requirements or there was a technical issue.'
  },
  form_kba: {
    ID: 'form_kba',
    icon: 'user',
    title: "Found you! Now, let's verify your identity",
    subTitle: 'Follow the prompts to verify your phone number.',
    submit: 'Submit',

    scrollable: true
  },
  'action:rejection': {
    ID: 'action:rejection',
    submit: 'DEBUG'
  },
  'action:debug_break': {
    ID: 'action:debug_break',
    submit: 'DEBUG'
  },
  enterCredentials: {
    ID: 'enterCredentials'
  },
  addExtraLayer: {
    ID: 'addExtraLayer'
  },
  verifyIdentity: {
    ID: 'verifyIdentity'
  },
  identityQuestions: {
    ID: 'identityQuestions'
  },
  receiveCode: {
    ID: 'receiveCode'
  }
}

export const CODE_VERIFICATION_ACTIONS = {
  CANCEL: 'cancel',
  RESEND: 'resend',
  SWITCH: 'switchMethod'
}

export const STORE_KEYS = {
  TRANSMIT: 'transmitSessionState',
  USERDATA: 'transmitUserData'
}
