import { useState } from 'react'

const urlParams = new URLSearchParams(window.location.search)
const ticketID = urlParams.get('ticketid')

const getSessionKey = (key) => `${key}-${btoa(ticketID)}`
const readSessionState = (storeKey) =>
  window.sessionStorage.getItem(getSessionKey(storeKey))
const writeSessionState = ({ transmitState, storeKey }) =>
  window.sessionStorage.setItem(getSessionKey(storeKey), transmitState)

const useSessionSerializedState = (storeKey) => {
  try {
    const [transmitState, setSessionState] = useState(() => {
      return readSessionState(storeKey)
    })

    const setTransmitState = (transmitState) => {
      setSessionState(transmitState)
      writeSessionState({ transmitState, storeKey })
    }

    return [transmitState, setTransmitState]
  } catch (err) {
    return [null, null]
  }
}

export default useSessionSerializedState
