import React, { useEffect } from 'react'
import { nmlogo } from '../public/svg.js'
import '../styles/style.css'

const Template = ({ children }) => {
  let logo = null
  useEffect(() => {
    if (
      document.getElementById('header-left') &&
      document.getElementById('header-left').innerHTML
    ) {
      document.getElementById('header-left').innerHTML = nmlogo
    }
  }, [])

  return (
    <>
      <header>
        {/* <link rel='stylesheet' type='text/css' href='../styles/style.css' /> */}
        <title>Northwestern Mutual Identity Proofing</title>
        <script
          src='https://platform-websdk.transmitsecurity.io/platform-websdk/1.4.1/ts-platform-websdk.js'
          id='platform-sdk'
        ></script>
      </header>
      <div>
        <div className='page'>
          <header>
            <div className='top'>
              <div className='center'>
                <span className='header-left' id='header-left'>
                  {' '}
                </span>
                <span className='header-mid' id='header-mid'>
                  New Account Registration
                </span>
                <span className='header-right' id='header-right'>
                  <a href='#'>Get help</a>
                </span>
              </div>
            </div>
            <div className='verification_steps' />
            <div className='center' id='verification_steps'></div>
          </header>
          <div className='page main'>
            <div className='content center' style={{ width: '600px' }}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Template
