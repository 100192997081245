// import '../App.css';
import React from 'react'
import { ClientResponseOptionType } from './sdk_interface.js'
// import { connect } from 'react-redux';
// import Favicon from 'react-favicon';

const Information = ({
  handleTransmitSubmit,
  setJourneyData,
  transmitData
}) => {
  const json_data = JSON.parse(transmitData)
  const handleButton = () => {
    // pageUtils.hide('login_form')
    setJourneyData({
      option: ClientResponseOptionType.ClientInput,
      data: {},
      headers: {}
    })
    //   handleTransmitSubmit()
  }

  return (
    <center>
      <h1>{json_data.title}</h1>
      <br />
      <div>{json_data.text}</div>
      <button
        id='info_button'
        className='full-width login rounded'
        onClick={handleButton}
      >
        {json_data.button_text}
      </button>
    </center>
  )
}

export default Information
