// import '../App.css';
import React from 'react'
import { ClientResponseOptionType } from './sdk_interface.js'

let answer_extracted_value = [0, 0, 0]

export const KBAVerification = ({ setJourneyData, transmitData }) => {
  // console.log('transmitData')
  // console.log(transmitData)
  // console.log(transmitData.data)
  let questionData = transmitData?.data.app_data.security_questions || null
  let question_extracted_value = []

  let question_ids = []
  let answered_ids = []

  const HandleSubmitKBA = async () => {
    let answered_questions = { answered_ids, question_ids }
    for (let i in answered_questions['question_ids']) {
      question_extracted_value.push(
        parseInt(answered_questions['question_ids'][i])
      )
    }

    let data = {
      option: ClientResponseOptionType.ClientInput,
      data: {
        response: [
          {
            questionid: question_extracted_value[0].toString(),
            answerid:
              answer_extracted_value[0] !== 0 ? answer_extracted_value[0] : null
          },
          {
            questionid: question_extracted_value[1].toString(),
            answerid:
              answer_extracted_value[1] !== 0 ? answer_extracted_value[1] : null
          },
          {
            questionid: question_extracted_value[2].toString(),
            answerid:
              answer_extracted_value[2] !== 0 ? answer_extracted_value[2] : null
          }
        ]
      }
    }
    setJourneyData(data)
  }

  const setQsNAs = () => {
    for (let num = 0; num < 3; num++) {
      question_ids.push(questionData.QuestionSet.Questions[num].QuestionId)
      for (let i in questionData.QuestionSet.Questions[num].Choices) {
        answered_ids.push(i.ChoiceId)
      }
    }
  }

  return (
    <>
      <div id='form_kba' className='column gap hidden'>
        <div className='column gap'>
          <h3 id='kba_form_title' style={{ fontWeight: 'bold' }}>
            {"Let's verify your identity."}
          </h3>
          <div
            id='kba_form_text'
            style={{ fontFamily: 'Sans-serif' }}
            className='column long-text'
          ></div>
          {questionData && questionData.QuestionSet ? (
            <>
              <form>
                {setQsNAs()}
                {fillQs(0, questionData)}
                {fillQs(1, questionData)}
                {fillQs(2, questionData)}
              </form>
            </>
          ) : (
            ''
          )}
        </div>
        <button
          href='#'
          id='KBA_submit_button'
          className='quarter-width rounded'
          onClick={HandleSubmitKBA}
        >
          Submit
        </button>
      </div>
    </>
  )
}
export default KBAVerification

export function fillQs (num, questionData) {
  // console.log("filling qs: " + questionData.QuestionSet.Questions[num].Text.Statement)
  // console.log(self)

  return (
    <>
      <label
        value={questionData.QuestionSet.Questions[num].Text.Statement}
        id={questionData.QuestionSet.Questions[num].QuestionId}
      >
        <br />
        {'Question ' +
          (num + 1) +
          '. ' +
          questionData.QuestionSet.Questions[num].Text.Statement}
      </label>
      <br />
      <label>
        <input
          type='radio'
          name={questionData.QuestionSet.Questions[num].Key}
          id={questionData.QuestionSet.Questions[num].Choices[0].ChoiceId}
          onClick={() => {
            answer_extracted_value[num] =
              questionData.QuestionSet.Questions[num].Choices[0].ChoiceId
          }}
        />
        <div className='kba_qs'>
          {questionData.QuestionSet.Questions[num].Choices[0].Text.Statement}
        </div>
      </label>
      <label>
        <input
          type='radio'
          name={questionData.QuestionSet.Questions[num].Key}
          id={questionData.QuestionSet.Questions[num].Choices[1].ChoiceId}
          onClick={() => {
            answer_extracted_value[num] =
              questionData.QuestionSet.Questions[num].Choices[1].ChoiceId
          }}
        />
        <div className='kba_qs'>
          {questionData.QuestionSet.Questions[num].Choices[1].Text.Statement}
        </div>
      </label>
      <label>
        <input
          type='radio'
          name={questionData.QuestionSet.Questions[num].Key}
          id={questionData.QuestionSet.Questions[num].Choices[2].ChoiceId}
          onClick={() => {
            answer_extracted_value[num] =
              questionData.QuestionSet.Questions[num].Choices[2].ChoiceId
          }}
        />
        <div className='kba_qs'>
          {questionData.QuestionSet.Questions[num].Choices[2].Text.Statement}
        </div>
      </label>
      <label>
        <input
          type='radio'
          name={questionData.QuestionSet.Questions[num].Key}
          id={questionData.QuestionSet.Questions[num].Choices[3].ChoiceId}
          onClick={() => {
            answer_extracted_value[num] =
              questionData.QuestionSet.Questions[num].Choices[3].ChoiceId
          }}
        />
        <div className='kba_qs'>
          {questionData.QuestionSet.Questions[num].Choices[3].Text.Statement}
        </div>
      </label>
      <label>
        <input
          type='radio'
          name={questionData.QuestionSet.Questions[num].Key}
          id={questionData.QuestionSet.Questions[num].Choices[4].ChoiceId}
          onClick={() => {
            answer_extracted_value[num] =
              questionData.QuestionSet.Questions[num].Choices[4].ChoiceId
          }}
        />
        <div className='kba_qs'>
          {questionData.QuestionSet.Questions[num].Choices[4].Text.Statement}
        </div>
      </label>
    </>
  )
}
