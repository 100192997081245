// import '../App.css';
import React from 'react'

const Rejection = ({ setJourneyData, initializeTransmit }) => {
  return (
    <center>
      <h1>Rejected!</h1>
      <button
        id='CompletionButton'
        className='full-width login rounded'
        onClick={initializeTransmit}
      >
        Restart Journey
      </button>
    </center>
  )
}

export default Rejection
