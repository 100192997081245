import React, { useEffect, useState } from 'react'
import tsPlatform from '../nm-transmit/vendor/ts'
import { STORE_KEYS } from '../consts'
import useSessionSerializedState from '../hooks/useSessionSerializedState'
import Template from './Template'
import Login from './Login'
import Information from './Information'
import PhoneVerification from './PhoneVerification'
import Success from './Success'
import KBAVerification from './KBAVerification'
import Rejection from './Rejection'

let TransmitSDK = null
let isTransmitInitialized = false

export const Home = () => {
  const [transmitState, setTransmitState] = useSessionSerializedState(
    STORE_KEYS.TRANSMIT
  )
  const [transmitData, setTransmitData] = useState(null)
  const [journeyData, setJourneyData] = useState({})
  const [pageID, setPageID] = useState('')
  let sdk = null

  const setTransmitResponse = async response => {
    const pageID = response.journeyStepId
    setPageID(response.journeyStepId)
    setTransmitData(response)
    await setTransmitState(TransmitSDK.serializeState())
    if (pageID === 'action:information') {
      setTransmitData(response.data)
    }
    console.log('response')
    console.log(response)
  }

  const initializeTransmit = async () => {
    try {
      if (!isTransmitInitialized) {
        await tsPlatform.initialize({
          clientId: 'demo-client-id',
          ido: {
            serverPath: 'https://northwestern-mutual-dev.transmit.security',
            applicationId: 'client_website_dev',
            logLevel: 0
          }
        })
        TransmitSDK = await tsPlatform.ido
        isTransmitInitialized = true
        sdk = tsPlatform.ido
      }

      let response
      response = await TransmitSDK.startJourney('test_app', {
        flowId: 'random',
        additionalParams: { plus: false, ui: 'testapp' }
      })
      setTransmitResponse(response)
    } catch (err) {
      console.log(err)
    }
  }

  const handleTransmitSubmit = async () => {
    try {
      let response = journeyData
      if (TransmitSDK != null) {
        const transmitResponse = await TransmitSDK.submitClientResponse(
          response.option,
          response.data,
          response.header,
          { clientId: 'demo-client-id' }
        )
        setTransmitResponse(transmitResponse)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    handleTransmitSubmit()
  }, [journeyData])

  return (
    <Template>
      {isTransmitInitialized === false ? (
        <>
          <button
            className='rounded full-width'
            title='JourneyStartButton'
            onClick={initializeTransmit}
          >
            Start Journey
          </button>
        </>
      ) : (
        ''
      )}
      {transmitData && pageID === 'action:information' ? (
        transmitData && transmitData.state === 'completed' ? (
          <Success
            setJourneyData={setJourneyData}
            initializeTransmit={initializeTransmit}
          />
        ) : (
          <Information
            transmitData={JSON.stringify(transmitData)}
            handleTransmitSubmit={handleTransmitSubmit}
            setJourneyData={setJourneyData}
          />
        )
      ) : (
        ''
      )}
      {transmitData && pageID === 'action:rejection' ? (
        <Rejection
          setJourneyData={setJourneyData}
          initializeTransmit={initializeTransmit}
          transmitData={JSON.stringify(transmitData)}
        />
      ) : (
        ''
      )}
      {transmitData && pageID === 'login_form' ? (
        <Login setJourneyData={setJourneyData} />
      ) : (
        ''
      )}
      {transmitData && pageID === 'form_phone_verification' ? (
        <PhoneVerification
          setJourneyData={setJourneyData}
          transmitData={transmitData}
        />
      ) : (
        ''
      )}
      {transmitData && pageID === 'form_kba' ? (
        <KBAVerification
          setJourneyData={setJourneyData}
          transmitData={transmitData}
        />
      ) : (
        ''
      )}
      {transmitData &&
      transmitData.data &&
      transmitData.data.control_flow &&
      transmitData.data.control_flow.type === 'debug_break' ? (
        <Information
          transmitData={JSON.stringify(transmitData)}
          handleTransmitSubmit={handleTransmitSubmit}
          setJourneyData={setJourneyData}
        />
      ) : (
        ''
      )}
    </Template>
  )
}

export default Home
