// import '../App.css';
import React from 'react'

const Success = ({ setJourneyData, initializeTransmit }) => {
  return (
    <center>
      <h1>Journey Finished!</h1>
      <button
        id='CompletionButton'
        className='full-width login rounded'
        onClick={initializeTransmit}
      >
        Restart the journey.
      </button>
    </center>
  )
}

export default Success
