import React, { useEffect } from 'react'
import { ClientResponseOptionType } from './sdk_interface.js'
import { blue_check, verification_steps } from '../public/svg.js'

const PhoneVerification = ({ setJourneyData, transmitData }) => {
  useEffect(() => {
    document.getElementById('verification_steps').innerHTML = verification_steps
    document.getElementById('otp_form_title').innerHTML =
      blue_check + 'Verify your identity'
  }, [])

  let tData = JSON.parse(JSON.stringify(transmitData))

  const HandleSubmitOTP = async () => {
    let data = {
      option: ClientResponseOptionType.ClientInput,
      data: {
        phone_number: tData.data.phone_number,
        otp_value: document.getElementById('otp_form_input').value,
        retry_otp_selection: null,
        resend: null,
        fallback_method: null
      }
    }

    ;(document.getElementById('otp_form_input').value = ''),
      setJourneyData(data)
  }

  function sendResend () {
    let data = {
      phone_number: tData.data.phone_number,
      option: ClientResponseOptionType.ClientInput,
      data: {
        otp_value: null,
        retry_otp_selection: null,
        resend: true,
        fallback_method: null
      }
    }
    setJourneyData(data)
  }

  function sendChange () {
    let retryType = tData.data.data.otp_type
      ? tData.data.data.otp_type
      : tData.data.data.retry_otp_selection
    let data = {
      option: ClientResponseOptionType.ClientInput,
      data: {
        phone_number: tData.data.phone_number,
        otp_value: null,
        retry_otp_selection: retryType === 'voice' ? 'sms' : 'voice',
        resend: null,
        fallback_method: null
      }
    }
    setJourneyData(data)
  }

  return (
    <>
      <div className='seperator' />

      <div
        id='otp_form'
        className='column gap hidden'
        style={{ textAlign: 'left' }}
      >
        <div className='column gap'>
          <h3 id='otp_form_title' style={{ fontWeight: 'bold' }}>
            <div className='verification_steps'></div>
          </h3>
          <div
            id='otp_form_text'
            style={{ fontFamily: 'Sans-serif' }}
            className='column long-text'
          ></div>
          Verification code
          <input
                  className='focus-visible form-input'
            type='text'
            maxLength='6'
            width={200}
            autoComplete='off'
            id='otp_form_input'
            placeholder='Enter verification code'
          />
          <a href='#' id='otp_resend_button' onClick={sendResend}>
            Resend
          </a>
          <a href='#' id='otp_change_button' onClick={sendChange}>
            Having trouble?
          </a>
          <button
            id='otp_form_button'
            className='quarter-width rounded'
            onClick={HandleSubmitOTP}
          >
            Verify
          </button>
        </div>
      </div>
    </>
  )
}

export default PhoneVerification
